import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import ProductList from "components/ProductList";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import CallSticky from "components/CallSticky"
class Product extends React.Component {
  render() {
    const data = this.props.data.allMarkdownRemark;
    
    const content = data ? 
      <div>
        
        <ProductList productEdges={data.edges}/>
        
      </div>
    : <h1>Khong co du lieu</h1>
  
    const seo = {
      siteTitle: "Sản phẩm của Hải Nam Media",
      siteDescription: "Với phương châm: Sử dụng các sản phẩm của mình phục vụ cho những nhu cầu thiết thực nhất của người dân để làm cho cuộc sống tốt đẹp hơn Hải Nam Media là Cầu nối thông tin ngành truyền thông.",
      siteCover: "/logos/logo-512.png"
    }
    
    return (
      <Layout location={this.props.location} title="Sản phẩm">
        <div className="index-container">
          <Helmet>
            <title>{'Sản phẩm'|config.siteTitle}</title>
            <link rel="canonical" href={`${config.siteUrl}/product/`} />
            <link href="/logos/favicon.ico" rel="icon" type="image/x-icon" />
          </Helmet>
          <SEO nonPostSEO={seo} /> 
          <header className="hn-bg-heading">
            <div className="bg_cover">
            <div className="container post-title hn-breadcrumb">
              <h1 className="display-4 text-uppercase text-left text-white">Sản phẩm</h1>
            </div>
            </div>
          </header>
          {content}

        </div>
        <CallSticky/>
      </Layout>
    );
  }
}

export default Product;

export const productQuery = graphql`
  query productQuery {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "product"}}},
      limit: 50
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            cover
            category
            shortDesc
            templateKey
          }
        }
      }
    }
  }
`;
